import React from 'react';
import config from '../../config';
import { useTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import StylesWrapper from './styles';
import NavigationMenuList from './components/NavigationMenuList';
import TopCollapseButton from './components/TopCollapseButton';
import TopBar from './components/TopBar';

function Navigation({
    isNavbarOpen,
    lightning,
    onChangeLightning,
    onNavButtonToggle,
}) {
    const stylesWrapper = StylesWrapper(lightning);
    const customThemeOverride = stylesWrapper.getCustomTheme();
    const useStyles = stylesWrapper.getUseStyles();
    const classes = useStyles();
    const theme = useTheme();

    return (
        <React.Fragment>
            <ThemeProvider theme={customThemeOverride}>
                <TopBar
                    classes={classes}
                    lightning={lightning}
                    onChangeLightning={onChangeLightning}
                    isNavbarOpen={isNavbarOpen}
                    onNavButtonToggle={onNavButtonToggle}
                    siteTitle={config.site.title}
                />
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={isNavbarOpen}
                    classes={{
                        paper: ` ${clsx({
                            [classes.drawerPaper]: lightning === 'Light',
                            [classes.drawerPaperDark]: lightning === 'Dark',
                        })}`,
                    }}
                >
                    <TopCollapseButton
                        classes={classes}
                        lightning={lightning}
                        theme={theme}
                        onNavButtonToggle={onNavButtonToggle}
                    />
                    <Divider />
                    <NavigationMenuList
                        classes={classes}
                        lightning={lightning}
                    />
                    <Divider />
                </Drawer>
            </ThemeProvider>
        </React.Fragment>
    );
}
export default Navigation;
