import React from 'react';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import LightningSwitch from '../../../Lightning';

import { useHistory } from 'react-router-dom';

const preventDefault = (event) => {
    event.preventDefault();
};

const TopBar = ({
    classes,
    lightning,
    onChangeLightning,
    onNavButtonToggle,
    isNavbarOpen,
    siteTitle,
}) => {
    const history = useHistory();

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: isNavbarOpen,
                [classes.themeLight]: lightning === 'Light',
                [classes.themeDark]: lightning === 'Dark',
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onNavButtonToggle}
                    edge="start"
                    className={clsx(
                        classes.menuButton,
                        isNavbarOpen && classes.hide
                    )}
                >
                    <MenuIcon />
                </IconButton>
                <a
                    onClick={(event) => {
                        preventDefault(event);
                        history.push('/');
                    }}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                >
                    <Typography variant="h6" noWrap>
                        {siteTitle}
                    </Typography>
                </a>
                <LightningSwitch
                    lightning={lightning}
                    onChangeLightning={onChangeLightning}
                />
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
