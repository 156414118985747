import { connect } from 'react-redux';
import App from '../index';

const CHANGE_LIGHTNING = 'CHANGE_LIGHTNING';
const TOGGLE_NAV_BUTTON = 'TOGGLE_NAV_BUTTON';
const USE_DEBUG_MODE = 'USE_DEBUG_MODE';

const initialState = {
    debugMode: false,
    isNavbarOpen: false,
    lightning: 'Light',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LIGHTNING:
            return Object.assign({}, state, {
                lightning: action.lightning,
            });
        case TOGGLE_NAV_BUTTON:
            return Object.assign({}, state, {
                isNavbarOpen: !state.isNavbarOpen,
            });
        case USE_DEBUG_MODE:
            return Object.assign({}, state, {
                debugMode: action.debugMode,
            });
        default:
            return state;
    }
};

const actionChangeLightning = (lightning) => {
    return {
        type: CHANGE_LIGHTNING,
        lightning: lightning,
    };
};

const actionToggleNavButton = () => {
    return {
        type: TOGGLE_NAV_BUTTON,
    };
};

const actionUseDebugMode = () => {
    return {
        type: USE_DEBUG_MODE,
    };
};

const mapStateToProps = (state) => {
    return {
        isNavbarOpen: state.reducerApp.isNavbarOpen,
        lightning: state.reducerApp.lightning,
    };
};

const mapDispatchToProps = (disaptch) => {
    return {
        onChangeLightning: (lightning) => {
            disaptch(actionChangeLightning(lightning));
        },
        onNavButtonToggle: () => {
            disaptch(actionToggleNavButton());
        },
    };
};

const connectApp = connect(mapStateToProps, mapDispatchToProps)(App);

export { connectApp as App, reducer as reducerApp };
