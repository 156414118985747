import React from 'react';
import NavigationGroup from '../NavigationGroup';
import config from '../../../../config';

const NavigationMenuList = ({ lightning, classes }) =>
    config.navigation.enabled.map((enabledModuleGroup, index) => (
        <NavigationGroup
            navItem={enabledModuleGroup}
            icon={config.navigation.icons[enabledModuleGroup]}
            navigationGroupName={enabledModuleGroup}
            key={index}
            lightning={lightning}
            classes={classes}
        />
    ));

export default NavigationMenuList;
