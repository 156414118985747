import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
const useStyles = makeStyles({
    root: {
        padding: '18px',
    },
    historyContainer: {
        height: '400px',
        overflow: 'scroll',
    },
    red: {
        color: 'red',
        verticalAlign: 'middle',
    },
    green: {
        color: 'green',
        verticalAlign: 'middle',
    },
});

const SystemMessageDisplay = ({ isProcessing, logHistory }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box>
                            <div className={classes.root}>
                                <Typography
                                    className={classes.title}
                                    variant="h5"
                                    display="block"
                                    gutterBottom
                                >
                                    System Messages{' '}
                                    {
                                        <FiberManualRecordTwoToneIcon
                                            className={
                                                isProcessing
                                                    ? classes.red
                                                    : classes.green
                                            }
                                        />
                                    }
                                </Typography>
                                <hr />
                                <h4>History</h4>
                                <div className={classes.historyContainer}>
                                    {logHistory.map((record, index) => (
                                        <span
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: record,
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default SystemMessageDisplay;
