import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const useStyles = makeStyles({
    root: {
        color: 'white',
        position: 'relative',
        right: 0,
    },
});
const LightningSwitch = ({ lightning, onChangeLightning }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <IconButton
                className={classes.root}
                aria-label="upload picture"
                component="span"
                onClick={() => {
                    onChangeLightning(lightning === 'Light' ? 'Dark' : 'Light');
                }}
            >
                {lightning === 'Light' ? (
                    <EmojiObjectsIcon />
                ) : (
                    <EmojiObjectsOutlinedIcon />
                )}
            </IconButton>
        </React.Fragment>
    );
};

export default LightningSwitch;
