import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FlightIcon from '@material-ui/icons/Flight';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import InfoIcon from '@material-ui/icons/Info';

import HotelIcon from '@material-ui/icons/Hotel';

import London from '../locations/world/London';
import Dubai from '../locations/world/Dubai';
import Amsterdam from '../locations/world/Amsterdam';
import AbuDhabi from '../locations/world/AbuDhabi';

import Eilat from '../locations/israel/Eilat';
import TelAviv from '../locations/israel/TelAviv';
import Ashkelon from '../locations/israel/Ashkelon';
import B7 from '../locations/israel/B7';
import BeitShean from '../locations/israel/BeitShean';
import Dor from '../locations/israel/Dor';
import EinGedi from '../locations/israel/EinGedi';
import Haifa from '../locations/israel/Haifa';
import Herzliya from '../locations/israel/Herzliya';
import Jerusalem from '../locations/israel/Jerusalem';
import K8 from '../locations/israel/K8';
import MitzpeRamon from '../locations/israel/MitzpeRamon';
import Netanya from '../locations/israel/Netanya';
import NeveIlan from '../locations/israel/NeveIlan';
import RamatGan from '../locations/israel/RamatGan';
import Ranana from '../locations/israel/Ranana';
import SdeBoker from '../locations/israel/SdeBoker';
import Tiberias from '../locations/israel/Tiberias';
import Yeruham from '../locations/israel/Yeruham';
import EinBokek from '../locations/israel/EinBokek';

/**
 Ashkelon
 B7
 BeitShean
 Dor
 EinGedi
 Haifa
 Herzliya
 Jerusalem
 K8
 MitzpeRamon
 Netanya
 NeveIlan
 RamatGan
 Ranana
 SdeBoker
 Tiberias
 Yeruham
 */

const config = {
    site: {
        title: 'green-shopping.co.il חוויית קניה חכמה',
    },
    navigation: {
        availiable: {
            Home: [],
            Hotels: [
                'Eilat',
                'Ashkelon',
                'B7',
                'BeitShean',
                'Herzliya',
                'Dor',
                'Haifa',
                'Tiberias',
                'EinBokek',
                'Yeruham',
                'Jerusalem',
                'MitzpeRamon',
                'NeveIlan',
                'Netanya',
                'EinGedi',
                'K8',
                'RamatGan',
                'Ranana',
                'SdeBoker',
                'TelAviv',
            ],
            Insurance: [],
            Cars: [],
            Flights: ['Amsterdam'],
            Shopping: [],
            Information: [],
        },
        enabled: ['Home', 'Hotels', 'Insurance', 'Cars', 'Flights', 'Shopping'],
        titles: {
            Home: 'ראשי',
            London: 'London',
            Dubai: 'Dubai',
            Amsterdam: 'Amsterdam',
            AbuDhabi: 'Abu-Dhabi',
            Ashkelon: 'אשקלון',
            B7: 'באר שבע',
            BeitShean: 'בית שאן',
            Dor: 'חוף דור',
            EinGedi: 'עין גדי',
            Haifa: 'חיפה',
            Herzliya: 'הרצליה',
            Jerusalem: 'ירושלים',
            K8: 'קרית שמונה',
            MitzpeRamon: 'מצפה רמון',
            Netanya: 'נתניה',
            NeveIlan: 'נווה אילן',
            RamatGan: 'רמת גן',
            Ranana: 'רעננה',
            SdeBoker: 'שדה בוקר',
            Tiberias: 'טבריה',
            Yeruham: 'ירוחם',
            Eilat: 'אילת',
            EinBokek: 'ים המלח',
            TelAviv: 'תל-אביב',
            Hotels: 'בתי מלון בארץ',
            Insurance: 'ביטוח',
            Cars: 'השכרת רכב',
            Flights: 'חו"ל',
            Shopping: 'קניות',
            Information: 'מידע נוסף',
        },
        routes: {
            Home: '/',
            London: 'london',
            TelAviv: 'tel-aviv',
            Dubai: 'dubai',
            Amsterdam: 'amsterdam',
            AbuDhabi: 'abu-dhabi',
            Eilat: 'eilat',
            Ashkelon: 'ashkelon',
            B7: 'beer-sheva',
            BeitShean: 'beit-shean',
            Dor: 'hof-dor',
            EinGedi: 'ein-gedi',
            EinBokek: 'ein-bokek',
            Haifa: 'haifa',
            Herzliya: 'herzliya',
            Jerusalem: 'jerusalem',
            K8: 'kiryat-shmona',
            MitzpeRamon: 'mitzpe-ramon',
            Netanya: 'netanya',
            NeveIlan: 'neve-ilan',
            RamatGan: 'ramat-gan',
            Ranana: 'ranana',
            SdeBoker: 'sde-boker',
            Tiberias: 'tiberias',
            Yeruham: 'yeruham',
            Hotels: 'hotels',
            Insurance: 'insirance',
            Cars: 'rent-a-car',
            Flights: 'flights',
            Shopping: 'shopping',
            Information: 'information',
        },
        icons: {
            Home: <HomeIcon />,
            Hotels: <HotelIcon />,
            Insurance: <CardTravelIcon />,
            Cars: <DriveEtaIcon />,
            Flights: <FlightIcon />,
            Shopping: <ShoppingCartIcon />,
            Information: <InfoIcon />,
        },
    },
    modules: {
        Home: <Jerusalem />,
        London: <London />,
        TelAviv: <TelAviv />,
        Dubai: <Dubai />,
        Amsterdam: <Amsterdam />,
        AbuDhabi: <AbuDhabi />,
        Eilat: <Eilat />,
        Insurance: <Amsterdam />,
        Cars: <Amsterdam />,
        Flights: <Amsterdam />,
        Shopping: <Amsterdam />,
        Information: <Amsterdam />,
        Ashkelon: <Ashkelon />,
        B7: <B7 />,
        BeitShean: <BeitShean />,
        Dor: <Dor />,
        EinGedi: <EinGedi />,
        EinBokek: <EinBokek />,
        Haifa: <Haifa />,
        Herzliya: <Herzliya />,
        Jerusalem: <Jerusalem />,
        K8: <K8 />,
        MitzpeRamon: <MitzpeRamon />,
        Netanya: <Netanya />,
        NeveIlan: <NeveIlan />,
        RamatGan: <RamatGan />,
        Ranana: <Ranana />,
        SdeBoker: <SdeBoker />,
        Tiberias: <Tiberias />,
        Yeruham: <Yeruham />,
    },
};

export default config;
