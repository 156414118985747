import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

const TopCollapseButton = ({
    classes,
    lightning,
    theme,
    onNavButtonToggle,
}) => (
    <div className={classes.drawerHeader}>
        <IconButton
            className={classes.iconButton}
            onClick={onNavButtonToggle}
            className={clsx({
                [classes.themeLight]: lightning === 'Light',
                [classes.themeDark]: lightning === 'Dark',
            })}
        >
            {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
            )}
        </IconButton>
    </div>
);

export default TopCollapseButton;
