import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
const drawerWidth = 240;

const StylesWrapper = (lightning) => {
    const _sobj = {
        useStyles: makeStyles((theme) => ({
            root: {
                display: 'flex',
            },
            nested: {
                paddingLeft: theme.spacing(4),
            },
            themeLight: {
                background: 'initial',
                color: '#fff',
            },
            themeDark: {
                background: 'black !important',
                color: 'greenyellow',
            },
            appBar: {
                display: 'flex',
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                background: 'green',
            },

            appBarShift: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: 'inherit',
            },
            MuiButtonBase: {
                float: 'left',
            },
            hide: {
                display: 'none',
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            drawerPaperDark: {
                background: 'black',
                color: 'greenyellow',
                width: drawerWidth,
            },
            drawerPaperLight: {
                width: drawerWidth,
                background: 'initial',
                color: 'initial',
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                ...theme.mixins.toolbar,
                justifyContent: 'flex-end',
                background: `${
                    lightning === 'Dark' ? 'black !important' : 'green'
                }`,
                border: `${lightning === 'Dark' ? '1px solid' : 'green'}`,
            },
            iconButton: {
                color: '#fff',
            },
            listItem: {
                textDecoration: 'none',
            },
        })),

        theme2: createMuiTheme({
            overrides: {
                MuiFormLabel: {
                    root: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        zIndex: `${
                            lightning === 'Dark' ? '99 !important' : 'initial'
                        }`,
                    },
                },
                MuiPaper: {
                    root: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        border: `${
                            lightning === 'Dark'
                                ? '1px solid yellowgreen !important'
                                : 'initial'
                        }`,
                    },
                },
                MuiInputBase: {
                    root: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        border: `${
                            lightning === 'Dark'
                                ? '1px solid yellowgreen !important'
                                : 'initial'
                        }`,
                    },
                },
                MuiButton: {
                    containedPrimary: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark'
                                ? 'turquoise !important'
                                : '#fff'
                        }`,
                        border: `${
                            lightning === 'Dark'
                                ? '1px solid turquoise !important'
                                : 'initial'
                        }`,
                    },
                    containedSecondary: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark' ? 'pink !important' : '#fff'
                        }`,
                        border: `${
                            lightning === 'Dark'
                                ? '1px solid pink !important'
                                : 'initial'
                        }`,
                    },
                },
                MuiButtonBase: {
                    root: {
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        border: `${
                            lightning === 'Dark' ? 'none !important' : 'initial'
                        }`,
                    },
                },
                MuiListItemIcon: {
                    root: {
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        border: `${lightning === 'Dark' ? 'none' : 'initial'}`,
                    },
                },
                MuiDrawer: {
                    root: {
                        background: `${
                            lightning === 'Dark'
                                ? 'black !important'
                                : 'initial'
                        }`,
                        color: `${
                            lightning === 'Dark'
                                ? 'yellowgreen !important'
                                : 'initial'
                        }`,
                        border: `${
                            lightning === '1px solid pink' ? 'none' : 'initial'
                        }`,
                    },
                },
            },
        }),

        getUseStyles: function () {
            return this.useStyles;
        },
        getCustomTheme: function () {
            return this.theme2;
        },
    };
    return _sobj;
};

export default StylesWrapper;
