import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '../../../common/Alert';
import LocationFeedback from '../../../common/LocationFeedback';

const Tiberias = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper elavation={10}>
                    <LocationFeedback location="טבריה" />
                </Paper>
                <Paper elavation={10}>
                    <ins
                        className="bookingaff"
                        data-aid="2203352"
                        data-target_aid="2203352"
                        data-prod="map"
                        data-width="100%"
                        data-height="590"
                        data-lang="ualng"
                        data-dest_id="0"
                        data-dest_type="landmark"
                        data-latitude="32.795859"
                        data-longitude="35.530973"
                        data-landmark_name="Tiberias"
                        data-mwhsb="0"
                        data-address="Tiberias, Israel"
                    >
                        <a href="//www.booking.com?aid=2203352">Booking.com</a>
                    </ins>
                    <script type="text/javascript">
                        {(function (d, sc, u) {
                            var s = d.createElement(sc),
                                p = d.getElementsByTagName(sc)[0];
                            s.type = 'text/javascript';
                            s.async = true;
                            s.src = u + '?v=' + +new Date();
                            p.parentNode.insertBefore(s, p);
                        })(
                            document,
                            'script',
                            '//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js'
                        )}
                    </script>
                </Paper>
            </Grid>
            <Alert />
        </Grid>
    );
};

export default Tiberias;
