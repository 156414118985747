import React, { Suspense } from 'react';
import config from '../config';
import clsx from 'clsx';
import Navigation from '../common/Navigation';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { SystemMessage } from '../common/SystemMessage/redux';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -240,
        minHeight: '100vw',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeaderSpacing: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    darkTheme: {
        background: 'black !important',
        color: 'greenyellow',
    },
}));

const toView = (location) => {
    for (const [key, value] of Object.entries(config.navigation.routes)) {
        if (value === location.pathname.slice(1)) {
            return key;
        }
        // value === location.pathname.slice(1) && (return key)
    }
    return 'Home';
};

function App({
    isNavbarOpen,
    lightning,
    onChangeLightning,
    onNavButtonToggle,
    location,
}) {
    const searchParams = queryString.parse(location.search);
    const classes = useStyles();
    return (
        <React.Fragment>
            {searchParams.key === '123_123' || true ? (
                <div className={classes.root}>
                    <CssBaseline />
                    <Navigation
                        isNavbarOpen={isNavbarOpen}
                        lightning={lightning}
                        onChangeLightning={onChangeLightning}
                        onNavButtonToggle={onNavButtonToggle}
                    />
                    <Suspense fallback={<div>Loading...</div>}>
                        <main
                            className={clsx(
                                {
                                    [classes.lightTheme]: lightning === 'Light',
                                    [classes.darkTheme]: lightning === 'Dark',
                                },
                                classes.content,
                                { [classes.contentShift]: isNavbarOpen }
                            )}
                        >
                            <div className={classes.drawerHeaderSpacing} />
                            <div className="contentBody">
                                {config.modules[toView(location)]}
                            </div>
                            {/* <SystemMessage />                         */}
                        </main>
                    </Suspense>
                </div>
            ) : (
                <Alert severity="error">
                    Wrong access key or key is missing!
                </Alert>
            )}
        </React.Fragment>
    );
}
export default App;
