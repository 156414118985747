import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const NavigationAnchorItem = ({ to, icon, text, className }) => {
    return (
        <a target="_self" href={to} style={{ textDecoration: 'none' }}>
            <ListItem button className={className}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        </a>
    );
};

export default NavigationAnchorItem;
