import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const LocationFeedback = ({ location }) => (
    <Box style={{ width: '100%', direction: 'rtl', padding: '10px' }}>
        <Typography
            style={{ display: 'inline-block', padding: '10px' }}
            direction="rtl"
            variant="h5"
            component="h1"
            gutterBottom
        >
            בתי מלון ב{location}
        </Typography>
        <Typography
            style={{ display: 'inline-block' }}
            direction="rtl"
            variant="h5"
            component="div"
            gutterBottom
        >
            |
        </Typography>
        <Typography
            style={{ display: 'inline-block', padding: '10px' }}
            direction="rtl"
            variant="h5"
            component="div"
            gutterBottom
        >
            מחיר ללילה עבור זוג מבוגרים
        </Typography>
    </Box>
);

export default LocationFeedback;
