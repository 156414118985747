import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import config from '../../../../config';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NavigationAnchorItem from '../NavigationAnchorItem';
import { useHistory } from 'react-router-dom';

const NavigationGroup = ({
    navItem,
    icon,
    lightning,
    navigationGroupName,
    classes,
}) => {
    const isCollapasable = config.navigation.availiable[navItem].length > 0;
    const [isExpanded, setIsExpanded] = useState(false);
    const history = useHistory();

    return (
        <React.Fragment>
            {navigationGroupName === 'Home' ? (
                <NavigationAnchorItem
                    to={config.navigation.routes[navigationGroupName]}
                    icon={icon}
                    text={config.navigation.titles[navigationGroupName]}
                    key={`fsdfdsfsfs`}
                    className={
                        lightning === 'Light'
                            ? classes.listItemLight
                            : classes.listItemDark
                    }
                />
            ) : (
                <ListItem
                    button
                    onClick={() => {
                        if (isCollapasable) {
                            setIsExpanded(!isExpanded);
                        } else {
                            history.push(
                                config.navigation.routes[navigationGroupName]
                            );
                        }
                    }}
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                        primary={config.navigation.titles[navigationGroupName]}
                    />

                    {isExpanded
                        ? isCollapasable && <ExpandLess />
                        : isCollapasable && <ExpandMore />}
                </ListItem>
            )}
            {config.navigation.availiable[navigationGroupName].length > 0 && (
                <Collapse
                    style={{ minHeight: 'auto' }}
                    in={isExpanded}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        {config.navigation.availiable[navigationGroupName].map(
                            (nestedInGroup, index) =>
                                navigationGroupName === 'Hotels' ||
                                navigationGroupName === 'Home' ? (
                                    <NavigationAnchorItem
                                        to={
                                            config.navigation.routes[
                                                nestedInGroup
                                            ]
                                        }
                                        icon={
                                            config.navigation.icons[
                                                nestedInGroup
                                            ]
                                        }
                                        text={
                                            config.navigation.titles[
                                                nestedInGroup
                                            ]
                                        }
                                        key={`navigationGroupName${nestedInGroup}${index}`}
                                        className={
                                            lightning === 'Light'
                                                ? classes.listItemLight
                                                : classes.listItemDark
                                        }
                                    />
                                ) : (
                                    <ListItem
                                        className={classes.nested}
                                        button
                                        key={`navigationGroupName${nestedInGroup}${index}`}
                                        onClick={() => {
                                            history.push(
                                                config.navigation.routes[
                                                    nestedInGroup
                                                ]
                                            );
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                config.navigation.titles[
                                                    nestedInGroup
                                                ]
                                            }
                                        />
                                    </ListItem>
                                )
                        )}
                    </List>
                </Collapse>
            )}

            {navigationGroupName === 'Home' && <Divider />}
        </React.Fragment>
    );
};

export default NavigationGroup;
