import { connect } from 'react-redux';
import SystemMessageDisplay from '../index';

const SYSTEM_PROCESS_START = 'SYSTEM_PROCESS_START';
const SYSTEM_PROCESS_END = 'SYSTEM_PROCESS_END';
const JUST_LOG = 'JUST_LOG';
const RESET = 'RESET';

const initialState = {
    isProcessing: false,
    systemProcessStart: false,
    systemSuccessMessage: '',
    systemFailureMessage: '',
    systemReset: false,
    messageHistory: [],
    logHistory: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case JUST_LOG:
            return Object.assign({}, state, {
                logHistory: state.logHistory.concat(action.msg),
            });
        case SYSTEM_PROCESS_START:
            return Object.assign({}, state, {
                isProcessing: true,
                logHistory: action.msg
                    ? state.logHistory.concat(action.msg)
                    : state.logHistory,
            });
        case SYSTEM_PROCESS_END:
            return Object.assign({}, state, {
                isProcessing: false,
                logHistory: action.msg
                    ? state.logHistory.concat(action.msg)
                    : state.logHistory,
            });

        default:
            return state;
    }
};

const actionSystemLog = (msg) => {
    return {
        type: JUST_LOG,
        msg: msg,
    };
};

const actionSystemProcessStart = (msg) => {
    return {
        type: SYSTEM_PROCESS_START,
        msg: msg,
    };
};

const actionSystemProcessEnd = (msg) => {
    return {
        type: SYSTEM_PROCESS_END,
        msg: msg,
    };
};

const actionSystemReset = () => {
    return {
        type: RESET,
    };
};

const mapStateToProps = (state) => {
    return {
        isProcessing: state.reducerSystemMessage.isProcessing,
        logHistory: state.reducerSystemMessage.logHistory,
    };
};

const connectSystemMessage = connect(mapStateToProps)(SystemMessageDisplay);

export {
    connectSystemMessage as SystemMessage,
    reducer as reducerSystemMessage,
    actionSystemReset,
    actionSystemLog,
    actionSystemProcessStart,
    actionSystemProcessEnd,
};
